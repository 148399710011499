import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvConfig } from '@penstock/models';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

fetch(environment.configUrl, { method: 'get' }).then(async response => {
  const envConfig: EnvConfig = await response.json();
  if (!envConfig.apiUrl) {
    throw new Error(
      'config.{env}.json not loaded correctly. Check that the file was replaced correctly during azure pipelines deployment.'
    );
  }
  if (envConfig.production) {
    enableProdMode();
  }
  const useValue = { ...envConfig };
  platformBrowserDynamic([{ provide: EnvConfig, useValue }]).bootstrapModule(AppModule);
});
