<p-toolbar>
  <div class="custom-toolbar">
    <div class="mr-4">
      <div [ngClass]="!sidebarVisible ? 'block' : 'lg:hidden'">
        <p-button pRipple
                  [icon]="PrimeIcons.BARS"
                  styleClass="p-button-text p-button-plain"
                  (onClick)="toggleSidebar()"></p-button>
      </div>
    </div>
    <div class="mx-0 sm:mr-2 md:mr-3 lg:mr-6 penstock-logo hidden md:block">
      <p-image [styleClass]="'light'"
               [src]="'../../assets/logo-penstock-light-theme.svg'"
               alt="Penstock Consulting"
               width="100%"></p-image>
      <p-image [styleClass]="'dark'"
               [src]="'../../assets/logo-penstock-dark-theme.svg'"
               alt="Penstock Consulting"
               width="100%"></p-image>
    </div>
    <div class="ml-3 text-2xl font-bold">{{clientName}}</div>
    <p-button pRipple
              styleClass="p-button-text p-button-plain"
              (onClick)="toggleTheme()">
      <fa-icon [icon]="faCircleHalfStroke"></fa-icon>
    </p-button>
  </div>
</p-toolbar>
<p-sidebar [modal]="false"
           [visible]="sidebarVisible"
           [showCloseIcon]="true"
           [dismissible]="true"
           (onHide)="sidebarVisible = false"
           position="left">
  <p-panelMenu [model]="versionOptions"
               [multiple]="false"></p-panelMenu>
</p-sidebar>
<div class="notes"
     [class.with-left-margin]="sidebarVisible"
     *ngIf="selectedVersion">
  <p-card id="top-of-notes"
          [styleClass]="'shadow-3'">
    <ng-template pTemplate="title">
      <div class="text-4xl">
        <i class="text-2xl"
           [ngClass]="PrimeIcons.BOOK"></i>
        <strong class="pl-3 pr-3">Release Notes</strong>
      </div>
      <penstock-divider></penstock-divider>
      <div class="inner-panel p-3 surface-border border-1 border-round">
        <div class="text-2xl mb-2">
          {{selectedVersion.clientName}} v{{selectedVersion.major}}.{{selectedVersion.minor}}.{{selectedVersion.patch}}
        </div>
        <div class="text-base font-normal">{{formatDate(selectedVersion.date)}}</div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div *ngFor="let section of selectedVersionSections; first as isFirst"
           [id]="section.id">
        <div class="text-3xl"
             [ngClass]="isFirst ? 'mt-4' : 'mt-6'">
          <i class="text-lg"
             [ngClass]="section.icon"></i>
          <strong class="pl-3">{{section.title}}</strong>
        </div>
        <penstock-divider></penstock-divider>
        <div class="inner-panel mb-3 p-3 surface-border border-1 border-round">
          <div class="editor-html"
               [innerHTML]="section.editor"></div>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>
