import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { AppState } from './store/app.state';
import { selectIsFetchingData } from './store/selectors/fetching-data.selectors';

@Component({
  selector: 'penstock-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})
export class AppComponent implements OnInit {
  isFetchingData$ = this.store.select(selectIsFetchingData);

  constructor(private store: Store<AppState>, private primengConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
}
