import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import * as c from '@penstock/data-access/contracts';
import { ByPassSecurityBlobPipe } from '@penstock/feature/by-pass-security-blob';
import { DividerComponent } from '@penstock/feature/divider';
import { EditorBlobApiService } from '@penstock/feature/editor-blob-api';
import { ThemeService } from '@penstock/feature/theme';
import { ToastService } from '@penstock/feature/toast';
import { format, parseISO } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { ToolbarModule } from 'primeng/toolbar';
import { forkJoin, map, mergeMap, Observable, of, Subscription, tap } from 'rxjs';
import { ReleaseNotesCoreApiService } from './../../services/api/release-notes-core.api.service';
import GetReleaseNotesVersionByClientPathIn = c.ReleaseNotes_GetReleaseNotesVersionByClientPath_In.GetReleaseNotesVersionByClientPathIn;
import GetReleaseNotesVersionByClientPathOut = c.ReleaseNotes_GetReleaseNotesVersionByClientPath_Out.GetReleaseNotesVersionByClientPathOut;
import GetReleaseNotesVersionsByClientPathIn = c.ReleaseNotes_GetReleaseNotesVersionsByClientPath_In.GetReleaseNotesVersionsByClientPathIn;
import VersionsSectionOut = c.ReleaseNotes_GetReleaseNotesVersionsByClientPath_Out.SectionOut;
import VersionOut = c.ReleaseNotes_GetReleaseNotesVersionsByClientPath_Out.VersionOut;
import SectionOut = c.ReleaseNotes_GetReleaseNotesVersionByClientPath_Out.SectionOut;

interface Version extends GetReleaseNotesVersionByClientPathOut {
  dateObject: Date;
  sections: Section[];
}

interface Section extends SectionOut {
  editor: string | null;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    PanelModule,
    PanelMenuModule,
    RouterModule,
    ButtonModule,
    FontAwesomeModule,
    ToolbarModule,
    DividerModule,
    ImageModule,
    FontAwesomeModule,
    ByPassSecurityBlobPipe,
    MenuModule,
    SidebarModule,
    DividerComponent
  ],
  templateUrl: './client-release-notes.component.html',
  styleUrls: ['./client-release-notes.component.scss']
})
export class ClientReleaseNotesComponent implements OnInit, OnDestroy {
  PrimeIcons = PrimeIcons;
  faCircleHalfStroke = faCircleHalfStroke;
  private subscriptions = new Subscription();
  clientUrlPath: string | null = null;
  versions: VersionOut[] = [];
  versionOptions: MenuItem[] = [];
  clientName?: string;
  clientId = 0;
  themes: { name: string; active: boolean }[] = [
    { name: 'primeng-bootstrap4-light-blue.theme', active: true },
    { name: 'primeng-bootstrap4-dark-blue.theme', active: false }
  ];
  sidebarVisible = true;
  selectedVersion?: Version;
  selectedVersionSections?: Section[] = this.selectedVersion?.sections;

  constructor(
    private route: ActivatedRoute,
    private releaseNotesCoreApiService: ReleaseNotesCoreApiService,
    private editorBlobApiService: EditorBlobApiService,
    private themeService: ThemeService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.subscribeToRouteParams();
    this.checkLocalStorageForTheme();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  checkLocalStorageForTheme(): void {
    const existingTheme = this.localStorageService.retrieve('theme');

    if (existingTheme) {
      this.themes.forEach(t => {
        if (t.name === existingTheme) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
      this.themeService.switchTheme(existingTheme);
    }
  }
  toggleTheme(): void {
    this.themes = this.themes.map(t => ({ ...t, active: !t.active }));
    const newActiveTheme = this.themes.find(t => t.active);
    if (newActiveTheme) {
      this.themeService.switchTheme(newActiveTheme.name);
      this.localStorageService.store('theme', newActiveTheme.name);
    }
  }

  private mapVersionOptions(versions: VersionOut[]): MenuItem[] {
    return versions.map((v, i) => ({
      id: `${v.id}`,
      label: `v${v.major}.${v.minor}.${v.patch}`,
      icon: i === 0 ? PrimeIcons.CIRCLE_FILL : PrimeIcons.CIRCLE,
      command: () => this.toggleVersion(v),
      items: v.sections.map(s => ({
        label: s.title,
        command: () => this.selectSectionInVersion(s, v)
      }))
    }));
  }

  private toggleVersion(version: VersionOut): void {
    if (this.sidebarVisible && window.innerWidth <= 768) {
      this.toggleSidebar();
    }
    if (version.id !== this.selectedVersion?.id) {
      this.openVersion(version.id).subscribe(() => {
        setTimeout(() => this.scroll({ scrollToTop: true, behavior: 'auto' }));
      });
    } else {
      this.scroll({ scrollToTop: true, behavior: 'smooth' });
    }
  }

  private selectSectionInVersion(section: VersionsSectionOut, version: VersionOut): void {
    if (this.sidebarVisible && window.innerWidth <= 768) {
      this.toggleSidebar();
    }
    if (version.id !== this.selectedVersion?.id) {
      this.openVersion(version.id).subscribe(() => {
        setTimeout(() => this.scroll({ sectionId: section.id, behavior: 'auto' }));
      });
    } else {
      this.scroll({ sectionId: section.id, behavior: 'smooth' });
    }
  }

  private subscribeToRouteParams(): void {
    const routeParamSubscription = this.route.paramMap.subscribe(params => {
      this.clientUrlPath = params.get('clientUrlPath');
      if (this.clientUrlPath) {
        setTimeout(() => this.getClientVersions()); /** To avoid ExpressionChangedAfterItHasBeenCheckedError */
      }
    });
    this.subscriptions.add(routeParamSubscription);
  }

  private getClientVersions() {
    const request: GetReleaseNotesVersionsByClientPathIn = { clientUrlPath: this.clientUrlPath ?? '' };
    this.releaseNotesCoreApiService.getReleaseNotesVersionsByClientPath(request).subscribe(response => {
      this.versions = response.versions;
      this.clientName = response.clientName;
      this.clientId = response.clientId;
      this.versionOptions = this.mapVersionOptions(response.versions);
    });
  }

  private openVersion(id: number): Observable<GetReleaseNotesVersionByClientPathOut> {
    const request: GetReleaseNotesVersionByClientPathIn = { id, clientUrlPath: this.clientUrlPath ?? '' };
    return this.releaseNotesCoreApiService.getReleaseNote(request).pipe(
      map(response => ({
        details: {
          ...response,
          dateObject: parseISO(response.date),
          sections: response.sections.map(s => ({ ...s }))
        },
        sectionsEditorBlobApiObs: forkJoin(
          response.sections.map(s =>
            s.editorGuid ? this.editorBlobApiService.getReleaseNoteEditorBlob(this.clientId, id, s.id, s.editorGuid) : of('')
          )
        )
      })),
      mergeMap(({ details, sectionsEditorBlobApiObs }) =>
        sectionsEditorBlobApiObs.pipe(
          map(blobs => ({
            ...details,
            sections: details.sections.map((s, i) => ({ ...s, editor: blobs[i] }))
          }))
        )
      ),
      tap(response => {
        this.selectedVersion = response;
        this.selectedVersionSections = this.selectedVersion.sections;
      })
    );
  }

  formatDate(date: string) {
    return format(parseISO(date), 'MMMM d, yyyy');
  }

  private scroll(o: { scrollToTop?: boolean; sectionId?: number; behavior: 'smooth' | 'auto' }): void {
    let el: HTMLElement | null;
    if (o.scrollToTop) {
      el = document.getElementById('top-of-notes');
    } else if (o.sectionId) {
      el = document.getElementById(`${o.sectionId}`);
    } else {
      this.toastService.showToastErrorMessageAndThrow('Error Scrolling');
    }
    if (el) {
      el.scrollIntoView({ behavior: o.behavior });
    }
  }

  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }
}
