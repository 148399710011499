import { Component } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  standalone: true,
  imports: [ProgressSpinnerModule],
  template: `<p-progressSpinner></p-progressSpinner>`,
  selector: 'penstock-loading',
  styles: [
    `
      :host {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 9999999;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.4);
      }
    `
  ]
})
export class LoadingComponent {}
