import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditorBlobApiService {
  constructor(private http: HttpClient) {}

  getReleaseNoteEditorBlob(clientId: number, releaseNoteId: number, sectionId: number, guid: string): Observable<string> {
    const blobName = this.getBlobName(clientId, releaseNoteId, sectionId, guid);
    const url = `https://penstockapi-releasenotesversionsection.azureedge.net/${blobName}`;
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  private getBlobName(clientId: number, releaseNoteId: number, sectionId: number, guid: string): string {
    return `clientid=${clientId}-releasenoteid=${releaseNoteId}-sectionid=${sectionId}-guid=${guid}`;
  }
}
