import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@penstock/feature/toast';
import { TSFixMe } from '@penstock/models';
import { catchError, Observable, tap, throwError } from 'rxjs';

const DEFAULT_ERROR = 'Something went wrong, please try again.';

@Injectable()
export class ApiErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<TSFixMe>> {
    return next.handle(request).pipe(
      tap(success => {
        try {
          if (success instanceof HttpResponse && success.body?.errors) {
            (success.body.errors as string[]).forEach(err => this.toastService.showToastInfoMessage(err));
          }
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(error => {
        if (!error.url?.includes('api/portal') && !error.url?.includes('api/admin-portal') && !error.url?.includes('api/release-notes')) {
          return next.handle(request);
        } else {
          try {
            if (error.error instanceof ErrorEvent) {
              this.toastService.showToastErrorMessage(`${error.error.message}`);
            } else {
              this.toastService.showToastErrorMessage(error?.error?.message ? `${error.error.message}` : DEFAULT_ERROR);
              if (error && error.error && error.error.errors && Object.keys(error.error.errors).length) {
                Object.values(error.error.errors).forEach((error: TSFixMe) => {
                  this.toastService.showToastErrorMessage(error as string);
                });
              }
            }
          } catch (e) {
            console.error(e);
            this.toastService.showToastErrorMessage(DEFAULT_ERROR);
          }
          return throwError(() => error);
        }
      })
    );
  }
}
