import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  switchTheme(theme: string) {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    themeLink.href = theme + '.css';
    const body = this.document.getElementById('body') as HTMLLinkElement;
    const DARK_THEME = 'dark-theme';
    const LIGHT_THEME = 'light-theme';
    if (theme.includes('dark')) {
      body.classList.add(DARK_THEME);
      body.classList.remove(LIGHT_THEME);
    } else {
      body.classList.remove(DARK_THEME);
      body.classList.add(LIGHT_THEME);
    }
  }
}
