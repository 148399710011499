import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';

@Component({
  standalone: true,
  imports: [CardModule],
  templateUrl: './not-found.component.html',
  styles: [':host { display: block; } ']
})
export class NotFoundComponent {}
