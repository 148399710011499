import { Injectable } from '@angular/core';
import { createAction, Store } from '@ngrx/store';
import { AppState } from '../app.state';

export const startFetchingData = createAction('[FETCHING DATA] Start');
export const stopFetchingData = createAction('[FETCHING DATA] Stop');

@Injectable({ providedIn: 'root' })
export class FetchingDataActionsService {
  constructor(private store: Store<AppState>) {}

  startFetchingData = (): void => this.store.dispatch(startFetchingData());
  stopFetchingData = (): void => this.store.dispatch(stopFetchingData());
}
