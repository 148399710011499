import { Injectable } from '@angular/core';
import { Message, MessageService, PrimeIcons } from 'primeng/api';

type Severity = 'error' | 'info' | 'success';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private messageService: MessageService) {}

  private messageFactory(severity: Severity, detail: string): Message {
    const message: Message = { severity, detail, life: 5000, summary: '', icon: '' };
    switch (severity) {
      case 'error':
        return { ...message, summary: 'Error', icon: PrimeIcons.EXCLAMATION_TRIANGLE };
      case 'info':
        return { ...message, summary: 'Info', icon: PrimeIcons.INFO_CIRCLE };
      case 'success':
        return { ...message, summary: 'Success', icon: PrimeIcons.CHECK };
      default:
        throw new Error('Unexpected Error when showing Toast message.');
    }
  }

  showToastErrorMessage(detail: string): void {
    const message = this.messageFactory('error', detail);
    this.messageService.add(message);
  }

  showToastErrorMessageAndThrow(detail: string): never {
    const message = this.messageFactory('error', detail);
    this.messageService.add(message);
    throw new Error(detail);
  }

  showToastInfoMessage(detail: string): void {
    const message = this.messageFactory('info', detail);
    this.messageService.add(message);
  }

  showToastSuccessMessage(detail: string): void {
    const message = this.messageFactory('success', detail);
    this.messageService.add(message);
  }
}
