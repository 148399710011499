import { Component } from '@angular/core';

@Component({
  standalone: true,
  template: `
    <div class="penstock-divider">
      <div class="uno"></div>
      <div class="dos"></div>
    </div>
  `,
  selector: 'penstock-divider',
  styles: [
    `
      @import 'libs/feature/theme/src/lib/variables.scss';
      :host {
        display: block;
      }
      .penstock-divider {
        margin: 20px 0;
        display: grid;
        grid-template-columns: 25px 1fr;
        > .uno,
        > .dos {
          height: 0;
          border-bottom-width: 3px;
          border-bottom-style: solid;
        }
        > .uno {
          border-bottom-color: $penstock-blue;
        }
        > .dos {
          border-bottom-color: var(--surface-100);
        }
      }
    `
  ]
})
export class DividerComponent {}
