import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as c from '@penstock/data-access/contracts';
import { EnvConfig } from '@penstock/models';
import { nameOf } from '@penstock/utils';
import { Observable } from 'rxjs';
import GetReleaseNotesVersionByClientPathIn = c.ReleaseNotes_GetReleaseNotesVersionByClientPath_In.GetReleaseNotesVersionByClientPathIn;
import GetReleaseNotesVersionByClientPathOut = c.ReleaseNotes_GetReleaseNotesVersionByClientPath_Out.GetReleaseNotesVersionByClientPathOut;
import GetReleaseNotesVersionsByClientPathIn = c.ReleaseNotes_GetReleaseNotesVersionsByClientPath_In.GetReleaseNotesVersionsByClientPathIn;
import GetReleaseNotesVersionsByClientPathOut = c.ReleaseNotes_GetReleaseNotesVersionsByClientPath_Out.GetReleaseNotesVersionsByClientPathOut;

@Injectable({ providedIn: 'root' })
export class ReleaseNotesCoreApiService {
  readonly #urlPlural = `${this.envConfig.apiUrl}/versions`;
  readonly #urlSingular = `${this.envConfig.apiUrl}/version`;

  constructor(private http: HttpClient, private envConfig: EnvConfig) {}

  getReleaseNotesVersionsByClientPath(request: GetReleaseNotesVersionsByClientPathIn): Observable<GetReleaseNotesVersionsByClientPathOut> {
    const params = new HttpParams().set(nameOf(request).clientUrlPath, `${request.clientUrlPath}`);
    return this.http.get<GetReleaseNotesVersionsByClientPathOut>(`${this.#urlPlural}`, { params });
  }

  getReleaseNote(request: GetReleaseNotesVersionByClientPathIn): Observable<GetReleaseNotesVersionByClientPathOut> {
    let params = new HttpParams();
    params = params.set(nameOf(request).id, /**     */ `${request.id}`);
    params = params.set(nameOf(request).clientUrlPath, `${request.clientUrlPath}`);
    return this.http.get<GetReleaseNotesVersionByClientPathOut>(`${this.#urlSingular}`, { params });
  }
}
