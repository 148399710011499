<div class="grid m-1">
  <div class="col-12">
    <p-card styleClass="shadow-4">
      <ng-template pTemplate="title">
        <div class="text-center">Page Not Found</div>
      </ng-template>
      <ng-template pTemplate="content"></ng-template>
    </p-card>
  </div>
</div>
