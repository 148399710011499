import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FetchingDataActionsService } from '../../store/actions';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private fetchingDataActionsService: FetchingDataActionsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.fetchingDataActionsService.startFetchingData();
    return next.handle(req).pipe(finalize(() => this.fetchingDataActionsService.stopFetchingData()));
  }
}
