import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Action, StoreModule } from '@ngrx/store';
import { ApiErrorHandlerInterceptor } from '@penstock/data-access/api-error-handler';
import { LoadingComponent } from '@penstock/feature/loading';
import { NotFoundComponent } from '@penstock/feature/not-found';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app.component';
import { ClientReleaseNotesComponent } from './routes/client-release-notes/client-release-notes.component';
import { LoadingInterceptor } from './services/interceptors/loading.interceptor';
import { AppState } from './store/app.state';
import { fetchingDataReducer } from './store/reducers';
import { NgxWebstorageModule } from 'ngx-webstorage';

const routes: Routes = [
  {
    path: 'client/:clientUrlPath',
    component: ClientReleaseNotesComponent,
    pathMatch: 'full'
  },
  { path: '**', component: NotFoundComponent }
];

type Reducer = (state: unknown | undefined, action: Action) => unknown;
const reducers: Record<keyof AppState, Reducer> = {
  fetchingData: fetchingDataReducer as Reducer
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top'
    }),
    StoreModule.forRoot(reducers),
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LoadingComponent,
    ToastModule
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorHandlerInterceptor, multi: true },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
