import { Action, createReducer, on } from '@ngrx/store';
import { fetchingDataActions } from '../actions';
import { FetchingDataState } from '../models';

const initialState: FetchingDataState = {
  isFetchingData: false,
  fetchingDataCount: 0
};

const _fetchingDataReducer = createReducer(
  initialState,

  on(fetchingDataActions.startFetchingData, (state): FetchingDataState => {
    const fetchingDataCount = state.fetchingDataCount + 1;
    const isFetchingData = fetchingDataCount > 0;
    return { isFetchingData, fetchingDataCount };
  }),

  on(fetchingDataActions.stopFetchingData, (state): FetchingDataState => {
    const fetchingDataCount = Math.max(state.fetchingDataCount - 1, 0);
    const isFetchingData = fetchingDataCount > 0;
    return { isFetchingData, fetchingDataCount };
  })
);

export function fetchingDataReducer(state: FetchingDataState, action: Action): FetchingDataState {
  return _fetchingDataReducer(state, action);
}
